import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';
import p1 from 'assests/Photos/LifeSkill2023/1.jpg';
import p2 from 'assests/Photos/LifeSkill2023/2.jpg';
import p3 from 'assests/Photos/LifeSkill2023/3.jpg';
import p4 from 'assests/Photos/LifeSkill2023/4.jpg';
import p5 from 'assests/Photos/LifeSkill2023/5.jpg';

import LifeSideBar from 'views/LifeSkill/components/LifeSideBar';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2023 from './Sidebar2023';
import Archive2022 from './Archive2022';

const LifeSkill2023 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
    //   {
    //     src: p6,
    //     source: p6,
    //     rows: 1,
    //     cols: 2,
    //   },

  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Interactive Session
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Class: 11 - 12  &  Date: 1 JULY 2023
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        An interactive session was conducted for students of classes 11 and 12 on Saturday, 1 July 2023 with 
        Mr Umang Goel, a highly accomplished individual and a proud alumnus of the prestigious Indian Institutes of 
        Technology (IIT). The event aimed to provide an insightful and inspiring platform for students to learn from the 
        experiences and achievements of a successful IIT graduate.
        <br></br>
        Mr Umang gave an insight about the different career paths that are available and how important it is for us as 
        students to come to a decision of what we want to pursue in our lives. He emphasised on the importance of continuous 
        learning, adaptability and embracing new technologies in today's rapidly evolving world. He said that his mantra 
        was – “Follow your passions and interest”. He stressed on the importance of finding one's true passion and 
        channelising that energy into making a meaningful impact. It was evident that his excitement was deeply intertwined 
        with his dedication to his craft.
        <br></br>
        The session proved to be an enriching and inspiring experience for all the students. They gained valuable insights 
        into the academic, professional and personal aspects of Mr Umang’s journey. The event served as a reminder of the 
        potential and opportunities that await IIT students.
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
“Education is not the filling of a pail, but the lighting of a fire” - William Butler Yeats

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <Sidebar2023 />
             </Box>

             <Box marginBottom={4}>
               <Archive2022 />
             </Box>

    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default LifeSkill2023;